<template>
  <div class="main">
    <img src="@/assets/画板1.png" alt="" />
  </div>
</template>

<style lang="less">
* {
  padding: 0;
  margin: 0;
}
.main {
  width: 100%;
  height: 100%;
  img {
    width: 100%;

    height: 100%;
    display: block;
  }
}
</style>
